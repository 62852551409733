import React from "react"
import Layout from "../components/layout.js"
import Clients from "../components/clients/clients.js"
import MakeItReal from "../components/MakeItReal.js"
import OurProcess from "../components/OurProcess.js"
import AnimatedText from "../components/AnimatedText.js"
import StartAProject from "../components/StartAProject/StartAProject.js"
import SEO from "../components/seo"
import styles from "./index.module.css"
import classNames from "classnames/bind"
import simpleScroll from "simple-scroll"
import ideas from "../images/ideas.svg"
import build from "../images/build.svg"
import launch from "../images/launch.svg"
import innovate from "../images/innovate.svg"

const isServer = typeof window === "undefined"
const cx = classNames.bind(styles)

// if (!isServer) {
// 	require('viewport-units-buggyfill').init();
// }

class Index extends React.PureComponent {
  constructor() {
    super()

    let isMobile = false

    if (!isServer) {
      isMobile = window.innerWidth < 900
    }

    this.state = {
      animationComplete: false,
      scrollOpacity: 1,
      progress: 1,
      isMobile,
    }

    this.ticking = false
  }

  /**
   * Lifecycle
   */

  componentDidMount() {
    setTimeout(() => {
      this.setState(() => ({
        animationComplete: true,
      }))
    }, 3300)

    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 1200)

    window.addEventListener("scroll", this.debounceScroll)
    window.addEventListener("resize", this.resizeHandler)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.debounceScroll)
  }

  /**
   * Handlers
   */

  resizeHandler = () => {
    const windowWidth = window.innerWidth
    if (this.state.isMobile && windowWidth >= 900) {
      this.setState(() => ({
        isMobile: false,
      }))
    }
    if (!this.state.isMobile && windowWidth < 900) {
      this.setState(() => ({
        isMobile: true,
      }))
    }
  }

  debounceScroll = () => {
    this.scrollPos = window.scrollY

    if (!this.ticking) {
      window.requestAnimationFrame(() => {
        this.scrollHandler()
        this.ticking = false
      })

      this.ticking = true
    }
  }

  scrollHandler = () => {
    const scrollHeight = window.innerHeight / 9
    const opacity = (scrollHeight - this.scrollPos) / scrollHeight
    const startPos =
      this.scrollPos + window.innerHeight / 3 - this.$approach.offsetTop + 150
    const endPos = startPos - this.$approach.offsetHeight
    let endPosStatic = 1

    if (startPos > 0) {
      endPosStatic = -endPos / this.$approach.offsetHeight
      if (endPosStatic <= 2) {
        endPosStatic *= 1.2
      } else {
        endPosStatic = 2
      }
    }

    this.setState(() => ({
      scrollOpacity: opacity,
      progress: endPosStatic,
    }))
  }

  scrollDown = ev => {
    ev.preventDefault()
    simpleScroll.element("innovate", 150, 300)
  }

  /**
   * Render
   */

  render() {
    const logo = (
      <h1
        className={styles.logo}
        style={{
          transform: `scale(1.${(
            "00" + Math.round(250 * (1 - this.state.progress))
          ).slice(-3)})`,
        }}
      >
        <AnimatedText
          speed={0}
          delay={1100}
          quick
          className={styles.logoContent}
        >
          <span style={{ opacity: this.state.progress }}>un</span>defined
          <span className={styles.logoDetail}>.</span>
        </AnimatedText>
      </h1>
    )

    return (
      <Layout noFade>
        <SEO />
        <div className={styles.container}>
          {!this.state.isMobile && logo}
          <div
            className={cx({
              intro: true,
              introAnimate: !this.state.animationComplete,
            })}
          >
            <div className={styles.introDetails}>
              <div className={styles.introDetails1} />
              <div className={styles.introDetails2} />
              <div className={styles.introDetails3} />
            </div>

            {this.state.isMobile && logo}

            <div className={styles.introTextWrapper}>
              <p
                className={styles.introText}
                style={{
                  opacity: this.state.scrollOpacity,
                  transform: `translateY(${(1 - this.state.scrollOpacity) *
                    50}px)`,
                }}
              >
                A digital studio building <a href="#">Apps</a>,{" "}
                <a href="#">Websites</a> & <a href="#">E-stores</a> for exciting
                startups & forward-thinking enterprises
                {/* <AnimatedText speed={0} delay={1250}>
                We create and develop digital products
              </AnimatedText>
              <AnimatedText speed={0.3} delay={1250}>
                with <span className={styles.introTextHighlight}>purpose</span>,
                building better solutions
              </AnimatedText>
              <AnimatedText speed={0.5} delay={1250}>
                {" "}
                that have a bigger impact.
              </AnimatedText> */}
              </p>
            </div>

            <a
              className={styles.approachBtn}
              href="#"
              onClick={this.scrollDown}
            >
              <span className={styles.approachArrow} />
              <AnimatedText
                className={styles.approachBtnText}
                speed={0.5}
                delay={2900}
              >
                Our
              </AnimatedText>
              <AnimatedText
                className={styles.approachBtnText}
                speed={0.7}
                delay={2900}
              >
                Approach
              </AnimatedText>
            </a>
          </div>

          <div className={styles.approach} ref={r => (this.$approach = r)}>
            <h2 className={styles.title}>Our Approach</h2>
            <div className={styles.approachItemIdeas}>
              <img
                className={styles.approachItemIllustration}
                src={ideas}
                alt="Ideas	icon"
              />
              <div className={styles.approachItemContent}>
                <h3 className={styles.approachItemTitle}>Research</h3>
                <p className={styles.approachItemContent}>
                  Everything starts with a need. We believe that mixing that
                  need and design (with a bit of coffee) is what makes your
                  message clear, convincing, and captivating. Exceptional user
                  experiences feel intuitive. We get close to your customers to
                  find out what they need.
                </p>
              </div>
            </div>
            {/* ---------- Our experiences are build We partner with businesses that
            have disruptive ideas and help them change the competitive
            landscape. */}
            <div className={styles.approachItemBuild}>
              <img
                className={styles.approachItemIllustration}
                src={build}
                alt="Build	icon"
              />
              <div className={styles.approachItemContent}>
                <h3 className={styles.approachItemTitle}>Build</h3>
                <p className={styles.approachItemContent}>
                  We take a no-nonsense approach to designing bespoke digital
                  platforms (web & app) that your users will love to use. We
                  capture audience attention through exceptional interaction and
                  visual design, whilst also focusing on high performance
                  development.
                </p>
              </div>
            </div>
            <div
              className={styles.approachItemLaunch}
              ref={r => (this.$innovate = r)}
            >
              <img
                className={styles.approachItemIllustration}
                src={launch}
                alt="Launch icon"
              />
              <div className={styles.approachItemContent}>
                <h3 className={styles.approachItemTitle}>Launch</h3>
                <p className={styles.approachItemContent}>
                  Our team is committed to launching high grade, future-proofed
                  products, allowing the founders to focus on the right things
                  like getting market ready, creating buzz, and working closely
                  with early customers to get feedback on the product.
                </p>
              </div>
            </div>
            <div className={styles.approachItemInnovate} id="innovate">
              <img
                className={styles.approachItemIllustration}
                src={innovate}
                alt="Innovate icon"
              />
              <div className={styles.approachItemContent}>
                <h3 className={styles.approachItemTitle}>Innovate</h3>
                <p className={styles.approachItemContent}>
                  We ensure that the product continues to iterate and innovate
                  beyond its time with us. We do this by supporting and
                  assisting you in transitioning to their own technical team.
                </p>
              </div>
            </div>
          </div>
        </div>
        <Clients />
        <MakeItReal />
        {/* <OurProcess /> */}
        <StartAProject />

        {/*<div className={styles.getInTouch}>
					<h2 className={styles.getInTouchText}>
						Sound good to you?
						<a href="mailto:hello@thisisundefined.com"><span>Get In Touch</span></a>
					</h2>
				</div> */}
      </Layout>
    )
  }
}

export default Index
