import React from "react"
import styles from "./StartAProject.module.css"
import Brainstorm from "./contact.jpg"
import { ArrowRight } from "react-feather"
import { Link } from "gatsby"

const StartAProject = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.boxForText}>
            <Link to="/contact" className={styles.letsmakesomething}>
              <h3>LET'S MAKE SOMETHING GREAT TOGETHER</h3>
            </Link>
            <div className={styles.arrowContainer}>
              <Link to="/contact" className={styles.startLink}>
                <h2>Start a Project 🚀</h2>
                <div className={styles.arrow}>
                  <span className={styles.linez}></span>
                  <span className={styles.arrowz}>
                    <ArrowRight />
                  </span>
                </div>
              </Link>
            </div>

            <p>
              or
              <a href="mailto:hello@thisisundefined.com">
                {" "}
                let's grab a coffee{" "}
              </a>
            </p>
          </div>
        </div>
        <div className={styles.imageContainer}>
          <img src={Brainstorm} alt="Siebe+Whiteboard" />
        </div>
      </div>
    </div>
  )
}

export default StartAProject
